import React, { useEffect } from 'react';
import Layout from 'components/layout';
import { navigate } from 'gatsby';

import bgImage from 'images/about.png';

const IndexPage = () => {
    useEffect(() => {
        navigate('/about');
    }, []);

    return (
        <Layout>
            <div className="fluid-container page-container about-container">
                <div className="container">
                    <img src={bgImage} className="bg-image" />
                    <div className="row">
                        <div className="col-sm-6 col-sm-offset-6 about-content">
                            <h2 className="page-title">About</h2>
                            <p className="lead-in">
                                Haircut One-Hundred was established in 1985 by
                                Kathy Bradley and is conveniently located in
                                Eagle Plaza, Voorhees, NJ. At Haircut
                                One-Hundred, you will be offered an array of
                                hair, nail and waxing services performed by our
                                talented staff in a relaxed and welcoming
                                atmosphere. Our Professionals work hard to make
                                our clients look and feel outstanding while
                                using the best products available in the
                                industry. We are available seven days a week and
                                look forward to beginning your transformation
                                today!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default IndexPage;
